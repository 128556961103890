import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import PageContext from "../context/PageContext"
import { ColorButton } from "../components/layout/ColorButton"
import { CheckCircleIcon, ChevronLeftIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import ProductsNew from "../components/goods/ProductsNew"
import { useEffect } from "react"

const VlozenoPage = ({ location }) => {
  const [context, dispatch] = useContext(PageContext)
  const item = context?.cart?.lastItem

  const query = "goods/items/" + item?.cat
  const cat = item?.cat

  let params = []
  params.push("priceVat:>:0")

  useEffect(() => {
    dispatch({
      type: "SET_BREADCRUMB",
      payload: {
        breadCrumbPath: [
          {
            menuName: item?.name,
            url: "/produkty/detail/" + item?.alias + "/",
          },
          { menuName: "Vloženo do košíku" },
        ],
      },
    })

    //eslint-disable-next-line
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vloženo do košíku | FÖDA</title>
      </Helmet>
      {item && (
        <>
          <div className="bg-gray-100 py-10 my-10">
            <div className="max-w-2xl mx-auto py-4  px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="flex flex-col-reverse w-full">
                <div className="  mx-auto grid lg:grid-cols-2 gap-8 w-full">
                  <div className="grid  grid-cols-3 bg-white  shadow-md rounded-md border w-full">
                    <div className="relative  rounded-md overflow-hidden m-3  ">
                      <div className="w-full h-full  ">
                        <div className="w-full h-full  object-center object-cover flex items-center p-3  ">
                          <img
                            src={item.img}
                            className="  object-contain lg:object-cover "
                            alt={item.name}
                          />
                        </div>
                      </div>
                      <div className="absolute top-0 right-0 left-0 bottom-0 inset-0 bg-black bg-opacity-5 w-full h-full group-hover:bg-opacity-10" />
                    </div>
                    <div className="  gap-4  col-span-2 ">
                      <div className="text-gray-600 mt-6 text-lg px-5 py-2">
                        {item.name}
                      </div>
                      <div className="text-green-600 flex items-center space-x-2 bg-green-600 bg-opacity-10 px-5 py-2 rounded-md m-3">
                        <CheckCircleIcon className="w-6 h-6" />{" "}
                        <span>Přidáno do košíku</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4 justify-between  lg:justify-end w-full">
                    <button
                      onClick={() => window.history.back()}
                      className={
                        "flex relative items-center space-x-4 px-4 py-3 text-gray-100 bg-gray-600 rounded-md border border-gray-700   hover:text-white hover:bg-gray-700 focus:outline-none shadow-md overflow-hidden "
                      }
                    >
                      <span className="flex justify-between items-center w-full">
                        <ChevronLeftIcon className="w-5 h-5 mr-5" />
                        Zpět
                      </span>
                    </button>
                    <Link to={"/kosik/"}>
                      <ColorButton text={"Pokračovat do košíku"} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="mt-20 lg:mt-28 mx-auto max-w-7xl">
        <ProductsNew
          location={location}
          paging={4}
          query={query}
          params={params}
          page={1}
          producer={""}
          cat={cat}
          hideHeader={true}
        />
      </div>
    </>
  )
}

export default VlozenoPage
